/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id:
    'eu-west-1:36204cf7-3645-4354-8611-5321ab8cc058',
  aws_cognito_region: 'eu-west-1',
  aws_user_pools_id: 'eu-west-1_b98kLLLsb',
  aws_user_pools_web_client_id: '4ofostu06rgmut2jb3aj1ong2',
  oauth: {}
};

export default awsmobile;
