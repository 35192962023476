import { Icon } from '@iconify/react';

import { useRef, useState, useEffect } from 'react';
import bellFill from '@iconify/icons-eva/bell-fill';
// material
import { Box, Divider, Typography, Badge } from '@material-ui/core';
import PropTypes from 'prop-types';

// utils
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
import { getNotification } from '../../utils/API';
// ----------------------------------------------------------------------

Notifications.propTypes = {
  jwtToken: PropTypes.string
};

export default function Notifications({ jwtToken }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState(undefined);

  useEffect(() => {
    getNotification(jwtToken)
      .then((res) => {
        setNotificationData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const month = notificationData === undefined ? 'nm' : notificationData.month;

  const dataAsOf =
    notificationData === undefined ? 'nm' : notificationData.dataAsOf;

  const dataCheck =
    notificationData === undefined ? 0 : notificationData.status;

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        color={open ? 'primary' : 'default'}
      >
        <Badge
          badgeContent=""
          color={dataCheck === 1 ? 'success' : 'error'}
          variant="dot"
        >
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorRef.current}
        sx={{ width: 360 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Re-Order for {month}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Data as of {dataAsOf}
            </Typography>
          </Box>
        </Box>

        <Divider />
      </MenuPopover>
    </>
  );
}
