import { useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMixPanel } from 'react-mixpanel-provider-component';

// material
import { alpha } from '@material-ui/core/styles';
import { Box, Avatar, Button, Divider, Typography } from '@material-ui/core';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

AccountPopover.propTypes = {
  setjwtToken: PropTypes.func,
  setUser: PropTypes.func,
  user: PropTypes.object
};

export default function AccountPopover({ user, setUser, setjwtToken }) {
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(false);
  const { mixpanel } = useMixPanel();

  const { name } = user;

  const userImage = user['custom:imageURL'];
  const position = user['custom:position'];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   Auth.currentAuthenticatedUser({
  //     bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  //   })
  //     .then((user) => setcurrentUser(user.attributes.email))
  //     .catch((err) => console.log(err));
  // });

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser();
      setjwtToken();
      mixpanel.reset();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar alt={name} src={userImage} />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {position}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="body2"
            component={RouterLink}
            to="/changepassword"
          >
            Change password
          </Button>
        </Box>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => signOut()}
          >
            Sign Out
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
