import axios from 'axios';

// const API = 'http://localhost:3000';
// const API = 'https://reorder.api.lanvin.autone.io';

let API = '';

if (
  window.location.href.includes('reorder.lanvin.autone.io') &&
  !window.location.href.includes('dev')
) {
  // production API in use
  API = 'https://reorder.api.lanvin.autone.io';
} else {
  // development API in use
  API = 'https://dev-reorder.api.lanvin.autone.io';
  // production code
}

axios.defaults.baseURL = API;

// Import

export function postImportData(jwtToken, ImportData, filename) {
  return axios.post(
    '/Import',
    { data: ImportData, filename },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

// get filters

export function getFilters(jwtToken) {
  return axios.get('/filters', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getUploads(jwtToken) {
  return axios.post(
    '/uploads',
    {},
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function deleteUpload(jwtToken, uploadid) {
  return axios.post(
    '/deleteuploads',
    { uploadid },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

// update status

export function updatestatus(jwtToken, statusData) {
  return axios.post(
    '/updatestatus',
    { data: statusData },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function getOrders(jwtToken) {
  return axios.get('/orders', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function saveEdits(jwtToken, statusData) {
  return axios.post(
    '/edit',
    { data: statusData },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function postSubmit(jwtToken) {
  return axios.post(
    '/submit',
    {},
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function postUnSubmit(jwtToken) {
  return axios.post(
    '/unsubmit',
    {},
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function checkSubmitted(jwtToken) {
  return axios.post(
    '/checksubmitted',
    {},
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export function getSubmissionImages(jwtToken) {
  return axios.get('/submissionimages', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getValidationStatus(jwtToken) {
  return axios.get('/validationstatus', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function getNotification(jwtToken) {
  return axios.get('/notifications', {
    headers: {
      Authorization: jwtToken
    }
  });
}

// get submission

export function getSubmission(jwtToken) {
  return axios.get('/submission', {
    headers: {
      Authorization: jwtToken
    }
  });
}

export function reorderEmail(jwtToken, useremail) {
  return axios.post(
    '/email/reorderemail',
    { useremail },
    {
      headers: {
        Authorization: jwtToken
      }
    }
  );
}

export default {
  postImportData,
  getFilters,
  getUploads,
  deleteUpload,
  getOrders,
  updatestatus,
  saveEdits,
  postSubmit,
  postUnSubmit,
  checkSubmitted,
  getSubmissionImages,
  getValidationStatus,
  getNotification,
  reorderEmail,
  getSubmission
};
