// routes for the app are here
// when making an app consider what the default root should be
// renders pages in the views folder

import React, { Suspense, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useMixPanel } from 'react-mixpanel-provider-component';
import Cohere from 'cohere-js';

// import { Auth } from 'aws-amplify';

import PropTypes from 'prop-types';
// material

// material
import { Box, Typography, Button } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';

//
import LoadingScreen from '../components/LoadingScreen';

import DashboardNavbar from '../layouts/dashboard/DashboardNavbar';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';

import { getValidationStatus } from '../utils/API';

// ---- Import Pages ------------------------------------------------------------------

// const GeneralEcommerce = React.lazy(() => import('../views/GeneralEcommerce')); // Lazy-loaded
const PageImport = React.lazy(() => import('../views/PageImport')); // Lazy-loaded

const ResetPassword = React.lazy(() =>
  import('../views/authentication/ResetPassword')
);

const Validation = React.lazy(() => import('../views/Validation'));
// ------------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light'
      ? alpha(theme.palette.primary.main, 0.08)
      : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object,
  setUser: PropTypes.func,
  setjwtToken: PropTypes.func,
  valueMetric: PropTypes.string,
  jwtToken: PropTypes.string,
  currentFilters: PropTypes.object,
  setCurrentFilters: PropTypes.func,
  validationOpen: PropTypes.bool,
  setValueMetric: PropTypes.func,
  searchData: PropTypes.array
};

function DashboardLayout({
  children,
  user,
  setUser,
  setjwtToken,
  setValueMetric,
  valueMetric,
  jwtToken,
  currentFilters,
  setCurrentFilters,
  validationOpen,
  searchData
}) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        user={user}
        setUser={setUser}
        setjwtToken={setjwtToken}
        valueMetric={valueMetric}
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
        jwtToken={jwtToken}
        searchData={searchData}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        user={user}
        setValueMetric={setValueMetric}
        valueMetric={valueMetric}
        jwtToken={jwtToken}
        setCurrentFilters={setCurrentFilters}
        currentFilters={currentFilters}
        validationOpen={validationOpen}
      />
      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}

Routes.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  setjwtToken: PropTypes.func,
  jwtToken: PropTypes.string
};

export default function Routes({ user, setUser, setjwtToken, jwtToken }) {
  const { enqueueSnackbar } = useSnackbar();

  const [valueMetric, setValueMetric] = useState('Retail');
  const [currentFilters, setCurrentFilters] = useState({});
  const [validationOpen, setValidationOpen] = useState(false);

  const [searchData, setSearchData] = useState([]);

  const { mixpanel } = useMixPanel();

  useEffect(() => {
    getValidationStatus(jwtToken)
      .then((res) => {
        if (res.data.open.data[0] === 1) {
          setValidationOpen(true);
        }
      })
      .catch((error) => {
        enqueueSnackbar('An error occured', { variant: 'error' });
        console.log(error);
      });

    Cohere.identify(
      user.email, // Required: can be any unique ID
      {
        displayName: `${user.name} ${user['custom:family_name']}`, // Optional
        email: user.email // Optional
      }
    );

    mixpanel.identify(user.email);
    mixpanel.people.set({
      $name: `${user.name} ${user['custom:family_name']}`,
      $email: user.email,
      $avatar: user['custom:imageURL']
    });

    mixpanel.track('Signed in');
  }, []);

  return (
    <Router>
      <Suspense fallback={<LoadingScreen />}>
        {user['custom:access_reorder'] === 'true' ? (
          <div>
            <DashboardLayout
              user={user}
              setUser={setUser}
              setjwtToken={setjwtToken}
              setValueMetric={setValueMetric}
              valueMetric={valueMetric}
              jwtToken={jwtToken}
              currentFilters={currentFilters}
              setCurrentFilters={setCurrentFilters}
              validationOpen={validationOpen}
              searchData={searchData}
            >
              <Switch>
                <Route path="/import">
                  <PageImport jwtToken={jwtToken} user={user} />
                </Route>
                <Route path="/validation">
                  <Validation
                    jwtToken={jwtToken}
                    currentFilters={currentFilters}
                    valueMetric={valueMetric}
                    user={user}
                    setCurrentFilters={setCurrentFilters}
                    setSearchData={setSearchData}
                  />
                </Route>
                <Route path="/changepassword">
                  <ResetPassword />
                </Route>

                <Route exact path="/">
                  <Redirect to="/import" />
                </Route>
              </Switch>
            </DashboardLayout>
          </div>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%'
            }}
            mt={5}
          >
            <Typography sx={{ width: 'fit-content' }}>
              You do not have access to the re-order module
            </Typography>

            <Box sx={{ px: 2.5, pb: 3, mt: 5, width: 'fit-content' }}>
              <DocStyle>
                <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
                  Need help?
                  <br /> Please contact us
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  href="mailto:support@autone.io"
                >
                  Support
                </Button>
              </DocStyle>
            </Box>
          </Box>
        )}
      </Suspense>
    </Router>
  );
}
